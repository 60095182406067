import React, { useState } from 'react'

import TextInput from '../base/TextInput'
import Button from '../base/Button'

import { isValidEmail } from '../../utils'

export default function EditEmail(props) {
  const { value, hasPassword, onUpdate = () => {} } = props

  const [email, setEmail] = useState(value)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)

  return (
    <div className='flex flex-col max-h-screen'>
      <TextInput
        value={email}
        type={'email'}
        onChange={(e) => setEmail(e.target.value)}
        label={'EMAIL'}
        placeholder={'Enter your email'}
      />
      {invalidEmail && <p className='text-red'>Please, enter a valid email</p>}

      <TextInput
        value={password}
        type={'password'}
        onChange={(e) => setPassword(e.target.value)}
        label={'PASSWORD'}
        placeholder={'Enter your password'}
      />
      {hasPassword && <p className='text-sm'>Your password is required in order to update your email</p>}

      {!hasPassword && (
        <>
          <TextInput
            value={confirmPassword}
            type={'password'}
            onChange={(e) => setConfirmPassword(e.target.value)}
            label={'CONFIRM PASSWORD'}
            placeholder={'Confirm your password'}
          />
          {confirmPassword && confirmPassword !== password && (
            <p className='text-sm text-red'>Passwords don&apos;t match</p>
          )}
        </>
      )}

      <Button
        className='mt-4 ml-auto w-full'
        outlined
        disabled={
          !password ||
          email === value ||
          (!hasPassword && (!password || !confirmPassword || password !== confirmPassword))
        }
        onClick={() => {
          if (!isValidEmail(email)) {
            return setInvalidEmail(true)
          }
          setInvalidEmail(false)
          onUpdate({ email, password, confirmPassword })
        }}
      >
        Save Changes
      </Button>
    </div>
  )
}
