import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Modal } from '@thepokencompany/ui'

import { toast } from '../base/Toaster'
import TextInput from '../base/TextInput'
import Button from '../base/Button'

import { useUpdatePass } from '../../graphql/profile'

export default function SetPass(props) {
  const { code, user, onLogin, onClose } = props

  const [UpdatePass] = useUpdatePass()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const onUpdatePass = async () => {
    try {
      const { data } = await UpdatePass({
        variables: {
          updateUserPassInput: { password, confirmPassword, code }
        }
      })
      if (data.updateUserPass) {
        toast.success('Password successfully updated')
        if (data.updateUserPass.mfa?.enabled) {
          onLogin({ mfa: data.updateUserPass })
        } else {
          onClose()
        }
      } else {
        toast.error('Fail to set new password. Please try again later')
      }
    } catch (error) {
      Sentry.captureException(error)
      toast.error('Fail to set new password. Please try again later')
    }
  }

  return (
    <>
      <Modal.Header>{`Connect with Pokmi account`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        <div className='flex flex-col p-2 justify-center items-center'>
          {/* <img src='/images/myPokenWallet.png' className='w-24 mb-2' alt='My Poken Wallet' /> */}
          <h2 className='flex-grow text-white text-center'>
            Set
            <span className='text-primary'> new password </span>
            for {user?.email}
          </h2>
        </div>

        <p className='text-xs'>Password length must be between 8 and 127 characters</p>

        <TextInput
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={'password'}
          placeholder={'New password'}
        />

        <TextInput
          value={confirmPassword}
          type={'password'}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder={'Confirm your password'}
        />
        {confirmPassword && confirmPassword !== password && (
          <p className='text-sm text-red'>Passwords don&apos;t match</p>
        )}

        <Button
          disabled={!password || !confirmPassword || password !== confirmPassword}
          className='cursor-pointer font-bold mt-4 w-full'
          onClick={onUpdatePass}
        >
          Confirm
        </Button>

        <div className='pt-4 w-full'>
          <p className='text-center cursor-pointer' onClick={onLogin}>
            Connect
          </p>
        </div>
      </div>
    </>
  )
}
