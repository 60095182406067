import { useEffect, useState, createContext, useContext, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'

const NavigationContext = createContext(null)
export const useNavigationContext = () => useContext(NavigationContext)

export function NavigationProvider(props) {
  const router = useRouter()

  const [previousPage, setPreviousPage] = useState('')

  const handleRouteChange = () => {
    let initialPage = window.location.pathname
    return (url) => {
      const { pathname } = window.location
      if (pathname === '/') {
        initialPage = ''
        return setPreviousPage('/')
      }
      setPreviousPage(pathname === initialPage || url === initialPage ? '' : url)
    }
  }

  const goBack = useCallback(() => {
    if (previousPage) router.back()
  }, [router, previousPage])

  useEffect(() => {
    if (router.events) {
      router.events.on('routeChangeComplete', handleRouteChange())
    }
  }, [router.events])

  const values = useMemo(
    () => ({
      previousPage,
      goBack
    }),
    [previousPage, goBack]
  )

  return <NavigationContext.Provider {...props} value={values} />
}
