import { useState, useCallback, useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import { LockClosedIcon } from '@heroicons/react/outline'

import { Modal } from '@thepokencompany/ui'

import { toast } from '../base/Toaster'
import TextInput from '../base/TextInput'
import Button from '../base/Button'
import ChooseWallet from '../ModalWallet/ChooseWallet'

import { isValidEmail, truncateWallet } from '../../utils'
import { useAPIContext } from '../../context/apiContext'
import { useWalletContext } from '../../context/walletContext'

import { useLoginUser } from '../../graphql/profile'
import { useTagManagerContext } from '../../context/tagManagerContext'

import styles from '../../styles/Login.module.scss' 
export default function Login(props) {
  const { mfaMode, onForgot, onCreate, onSuccess } = props

  const { apiRequest } = useAPIContext()
  const { loginWallet, client, onChooseWallet } = useWalletContext()
  const { sendData } = useTagManagerContext()

  const [Login] = useLoginUser()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [recoverMfa, setRecoverMfa] = useState(false)
  const [password, setPassword] = useState('')
  const [verifyMfa, setVerifyMfa] = useState(!!mfaMode)
  const [token, setToken] = useState('')
  const [user, setUser] = useState(mfaMode || null)

  useEffect(() => {
    sendData('sign_in', { action: 'display' })
  }, [])

  const onLogin = useCallback(async () => {
    if (!isValidEmail(email)) {
      return setEmailError(true)
    } else {
      setEmailError(false)
    }
    try {
      const { data } = await Login({
        variables: {
          loginInput: { email, password }
        }
      })
      if (data.login) {
        setUser(data.login)
        if (data.login.mfa?.enabled) return setVerifyMfa(true)
        toast.success('Successfully logged in')
        sendData('sign_in', { action: 'success', method: 'email' })
        onSuccess()
      } else {
        toast.error(`Fail to log in. Invalid email or password`)
      }
    } catch (error) {
      Sentry.captureException(error)
      toast.error(`Fail to log in. ${error.message || 'Invalid email or password'}.`)
    }
  }, [Login, email, password])

  const onVerifyMfa = async () => {
    try {
      const { data } = await apiRequest({
        method: 'post',
        path: `/mfa/validate`,
        body: { token, userId: user?.id }
      })
      if (data.validated) {
        toast.success('Successfully logged in')
        onSuccess()
      } else {
        toast.error('Fail to log in. Invalid 2FA token')
      }
    } catch (error) {
      Sentry.captureException(error)
      toast.error(`Failed to check 2FA authentication: ${error.message}`)
    }
  }

  const onLoginWallet = async () => {
    try {
      await loginWallet()
      onSuccess()
    } catch (error) {
      Sentry.captureException(error)
      toast.error(`Failed to log using wallet: ${error.message}`)
    }
  }

  return (
    <>
      <Modal.Header>{`Connect with Pokmi account`}</Modal.Header>
      {/* <Modal.Separator /> */}
      {recoverMfa && (
        <div className='flex flex-col items-center max-h-screen'>
          <LockClosedIcon className='w-20 h-20' />

          <h2 className='flex-grow text-white text-center my-4'>
            Recover your account by using the <span className='text-primary'>wallet linked</span> to your account <br />
            {user?.wallet && <span>({truncateWallet(user?.wallet)})</span>}
          </h2>

          <Button className='mt-4 ml-auto w-full' onClick={onLoginWallet}>
            Recover account
          </Button>

          <div className='pt-4 w-full'>
            <p className='text-center cursor-pointer' onClick={() => setRecoverMfa(false)}>
              Back
            </p>
          </div>
        </div>
      )}
      {verifyMfa && !recoverMfa && (
        <div className='flex flex-col items-center max-h-screen'>
          <LockClosedIcon className='w-20 h-20' />

          <h2 className='flex-grow text-white text-center my-4'>
            Verify the token from your <span className='text-primary text'>2FA Authentication</span> app
          </h2>

          <TextInput value={token} onChange={(e) => setToken(e.target.value)} placeholder={'Enter the token value'} />

          <Button className='mt-4 ml-auto w-full' disabled={!token} onClick={onVerifyMfa}>
            Verify Token
          </Button>

          {user?.wallet && (
            <div className='text-neutral-4 text-sm underline mt-4 cursor-pointer' onClick={() => setRecoverMfa(true)}>
              <p>Recover 2FA using your linked wallet</p>
            </div>
          )}
        </div>
      )}
      {!verifyMfa && !recoverMfa && (
        <div className='flex flex-col max-h-screen'>
          {/* <TextInput
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type={'email'}
            placeholder={'E-mail address'}
          />

          <TextInput
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type={'password'}
            placeholder={'Password'}
          />

          {emailError && <p className='text-red'>Please, enter a valid e-mail address</p>}

          <Button disabled={!email || !password} className={styles.btnLogin} onClick={onLogin}>
            Log in
          </Button>

          <div className='mt-4'>
            <p className='text-center cursor-pointer' onClick={onForgot}>
              Forgot password or no password yet ?
            </p>

            {/* <div className='font-bold mt-4'>
              <Button outlined className='font-bold w-full cursor-pointer' onClick={onCreate}>
                Create an account
              </Button>
            </div> 
          </div> */}

          <div className={styles.divider}>
            <p className='text-center cursor-pointer'>Connect with Wallet</p>

            <ChooseWallet
              modal={false}
              onChoose={async (provider, preferredChain) => {
                const walletProvider = await onChooseWallet(provider, preferredChain)
                await loginWallet(preferredChain, false, walletProvider)
              }}
              providers={client?.userOptions || []}
            />
          </div>
        </div>
      )}
    </>
  )
}
