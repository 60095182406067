import { useState } from 'react'
const isBrowser = typeof window !== 'undefined'
import * as Sentry from '@sentry/nextjs'

export function useLocalStorage(key, initialValue = null) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = isBrowser ? window.localStorage.getItem(key) : initialValue
      const parsedItem = item ? JSON.parse(item) : initialValue

      if (parsedItem?.ttl && Date.now() > parsedItem?.ttl) {
        removeValue()
        return null
      }
      return parsedItem?.ttl ? parsedItem.value : parsedItem || initialValue
    } catch (error) {
      return initialValue
    }
  })
  const setValue = (value, ttl) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (isBrowser)
        window.localStorage.setItem(
          key,
          JSON.stringify(ttl ? { value: valueToStore, ttl: Date.now() + ttl * 1000 } : valueToStore)
        )
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const removeValue = () => {
    try {
      window.localStorage.removeItem(key)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  return [storedValue, setValue, removeValue]
}

export function useSessionStorage(key, initialValue = null) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = isBrowser ? window.sessionStorage.getItem(key) : initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (isBrowser) window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return [storedValue, setValue]
}
