const TYPE_CLASS_NAMES = {
  primary: `bg-primary border-primary text-black`,
  'primary-outlined': `bg-neutral-1 border-primary text-primary hover:bg-primary hover:text-black`,
  secondary: '',
  white: 'bg-transparent border-white text-white hover:bg-neutral-1',
  black: 'bg-neutral-2 border-neutral-3 text-neutral-6 hover:bg-neutral-1',
  red: 'text-red border-red hover:bg-red hover:text:white',
  'red-outlined': 'bg-neutral-1 text-red border-red hover:bg-red hover:text-black'
}

export default function Button(props) {
  const { id, children, type = 'primary', outlined, className, disabled, onClick } = props

  const widthRegex = /\sw-[a-z0-9\/]+/g
  const customWidth = className?.match(widthRegex)?.length

  const typeClasses = TYPE_CLASS_NAMES[outlined ? type + '-outlined' : type] || TYPE_CLASS_NAMES.primary
  const classes = disabled
    ? 'cursor-default bg-blight text-g3 flex items-center justify-center'
    : `${typeClasses} border animation duration-500 flex items-center justify-center`

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${classes} ${customWidth || 'w-36'} h-10 text-sm  p-2 px-4 rounded outline-none  ${className}`}
    >
      <span id={id} className='truncate flex items-center'>
        {children}
      </span>
    </button>
  )
}
