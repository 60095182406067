import React, { useState } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Modal } from '@thepokencompany/ui'

import Button from '../base/Button'
import { toast } from '../base/Toaster'

import { useWalletContext } from '../../context/walletContext'
import { useUserContext } from '../../context/userContext'

import { useLoginWallet } from '../../graphql/profile'
import { useTagManagerContext } from '../../context/tagManagerContext'

import styles from '../../styles/Login.module.scss' 
export default function LoginWallet(props) {
  const { provider, onUpdate = () => {} } = props

  const { getMe } = useUserContext()
  const { getSignatureContent } = useWalletContext()
  const { sendData } = useTagManagerContext()

  const [LoginWallet] = useLoginWallet()

  const [logging, setLogging] = useState(false)

  const onLinkWallet = async () => {
    try {
      setLogging(true)

      const signatureContent = await getSignatureContent('login', provider)

      const { data } = await LoginWallet({
        variables: { walletUserInput: signatureContent }
      })
      if (!data?.loginWallet?.id) {
        setLogging(false)
        return toast.error(`Fail to login using wallet`)
      }
      toast.success('Successfully logged in using wallet')

      if (data?.loginWallet?.creation_method === 'wallet' && data?.loginWallet?.newly_created) {
        sendData('sign_up', {
          action: 'success',
          method: provider.name === 'MetaMask' ? 'wallet_metamask' : 'wallet_walletconnect'
        })
      }

      sendData('sign_in', {
        action: 'success',
        method: provider.name === 'MetaMask' ? 'wallet_metamask' : 'wallet_walletconnect'
      })

      onUpdate ? onUpdate() : await getMe()
      setLogging(false)
    } catch (error) {
      setLogging(false)
      Sentry.captureException(error)
      toast.error(`Fail to login using wallet: ${error.message}`)
    }
  }

  return (
    <>
      <Modal.Header>{`Login ${provider.name}`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        <div className='flex flex-col justify-center items-center'>
          <div className='w-20 w-full flex justify-center'>
            <picture className={styles.logoProvider}>
              <source srcSet={provider.logo} type="image/svg"/>
              <img  src={provider.logo} alt={provider.name}/>
            </picture>
          </div>

          <h2 className='flex-grow text-white text-center mt-4'>
            Connect {provider.name} wallet to log in using your Pokmi account
          </h2>

          <Button disabled={logging} className='mt-4 ml-auto w-full' outlined onClick={onLinkWallet}>
            Sign Transaction in your Wallet
          </Button>

          {logging && (
            <p className='mt-2 text-sm'>
              A signing transaction has been send to you wallet. Please confirm it to continue.
            </p>
          )}
        </div>
      </div>
    </>
  )
}
