import { useLayoutEffect, useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'

export function escapeHtml(unsafe: string): string {
  if (typeof unsafe !== 'string') return unsafe
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export function escapeHtmlProps(initialObj: any, keys: string[]) {
  return keys.reduce(
    (obj, key) => {
      obj[key] = escapeHtml(obj[key])
      return obj
    },
    { ...initialObj }
  )
}

export function truncateWallet(wallet: string, long?: number) {
  if (!wallet) return ''

  return `${wallet.substring(0, long || 5)}...${wallet.substring(wallet.length - (long - 1 || 4), wallet.length)}`
}

export const isBrowser = typeof window !== 'undefined'

export const isValidEmail = (email: string): boolean => {
  const EMAIL_REGEX =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  return EMAIL_REGEX.test(email)
}

export const isValidUsername = (username: string): boolean => {
  return /^[0-9a-z.]+$/.test(username)
}

export const validateInputAddresses = (address): boolean => {
  return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address)
}

export function useQueryString(key: string, initialState?: string): [string | undefined, Function] {
  const router = useRouter()
  function getValue(): string | undefined {
    const qsValue = router.query[key]
    if (!qsValue) return initialState
    return decodeURIComponent(Array.isArray(qsValue) ? qsValue[0] : qsValue)
  }

  function setValue(value?: string) {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        [key]: encodeURIComponent(value)
      }
    })
  }

  return [getValue(), setValue]
}

export function useQueryStringNum(key: string, initialState?: number): [number | undefined, Function] {
  const router = useRouter()

  function getValue(): number | undefined {
    const qsValue = router.query[key]
    if (!qsValue) return initialState
    return parseInt(decodeURIComponent(Array.isArray(qsValue) ? qsValue[0] : qsValue))
  }

  function setValue(value?: number) {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        [key]: encodeURIComponent(value)
      }
    })
  }

  return [getValue(), setValue]
}

export function isVideo(url) {
  if (!url) return false
  try {
    const contentUrl = new URL(url)
    const pathname = contentUrl.pathname
    return (
      url.toLowerCase().endsWith('mp4') ||
      pathname.toLowerCase().endsWith('mp4') ||
      url.toLowerCase().endsWith('mov') ||
      pathname.toLowerCase().endsWith('mov') ||
      url.toLowerCase().endsWith('m3u8') ||
      pathname.toLowerCase().endsWith('m3u8')
    )
  } catch (error) {
    return url.toLowerCase().endsWith('mp4') || url.toLowerCase().endsWith('mov') || url.toLowerCase().endsWith('m3u8')
  }
}

export const useBodyScrollLock = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [])
}

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export function formatPokenAmount(amount: number) {
  if (typeof amount !== 'number') return 'N/A'
  return new Intl.NumberFormat('en-US', {}).format(amount < 1 ? amount : Math.floor(amount) || 0).replace(/,/g, ' ')
}

export function getResourceUrl(resource) {
  if (!resource) return
  if (!resource.id) return resource

  const url = resource.url || resource.animation_url || resource.image
  const parsedUrl = url.split(`${resource.id}/`)
  const resourcePath = parsedUrl[parsedUrl.length - 1]
  const resourceId = resourcePath.split('.')[0]

  return {
    url,
    compressed: `${parsedUrl[0]}${resource.id}/${resourceId}_compressed.mp4`,
    thumbnail: `${parsedUrl[0]}${resource.id}/${resourceId}_thumbnail.png`
  }
}

export function pickSet(array, count = 1) {
  const result = []
  const remaining = [...array]
  for (let i = 0; i < count; i++) {
    const x = Math.floor(Math.random() * remaining.length)
    result.push(...remaining.splice(x, 1))
  }
  return result
}


export function getHashCode(str = '') {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i)
    hash = (hash << 5) - hash + character
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

interface UtmConfig {
  source?: string
  medium?: string
  campaign?: string
}

export function addUtms(url: string, utms: UtmConfig = {}) {
  try {
    const parsed = new URL(url)
    Object.entries(utms).forEach(([key, value]) => parsed.searchParams.set('utm_' + key, value))
    return parsed.toString()
  } catch (e) {
    console.error(e)
    return url
  }
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function isSoft(item: any) {
  return item && item.explicit === false
}

export function formatVodScore(likes) {
  if (!likes?.like) return 0

  return Math.round((likes.like / (likes.like + (likes.dislike || 0))) * 100)
}

export function formatNumber(number = 0) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(number)
}

export const randomIntFromInterval = (min = 100, max = 10000) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function nFormatter(num, digits = 1) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export async function wait(duration?) {
  await new Promise((resolve) => setTimeout(resolve, duration || 1000))
}


export function isUserOwner(user, nft) {
  if (!user || !nft?.owner) return false

  const nftWallet = []
  if (nft?.owner?.wallet) nftWallet.push(nft.owner.wallet)
  if (nft?.owner?.cloud_wallet) nftWallet.push(nft.owner.cloud_wallet)

  const userWallet = []
  if (user.wallet) userWallet.push(user.wallet)
  if (user.cloud_wallet) userWallet.push(user.cloud_wallet)

  return !!nftWallet.find((wallet) => userWallet.includes(wallet))
}

export function isUserCreator(user, nft) {
  if (!user || !nft?.creator) return false

  const nftWallet = []
  if (nft?.creator.wallet) nftWallet.push(nft.creator.wallet)
  if (nft?.creator.cloud_wallet) nftWallet.push(nft.creator.cloud_wallet)

  const userWallet = []
  if (user.wallet) userWallet.push(user.wallet)
  if (user.cloud_wallet) userWallet.push(user.cloud_wallet)

  return !!nftWallet.find((wallet) => userWallet.includes(wallet))
}

export function computePlanSavings(price, priceOneMonth, duration) {
  const supposedTotalPrice = priceOneMonth * duration
  const priceDifference = supposedTotalPrice - price
  return Math.round((priceDifference / supposedTotalPrice) * 100)
}
