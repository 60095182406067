import gql from 'graphql-tag'

export const LINK_WALLET = gql`
  mutation linkWallet($walletUserInput: WalletUserInput!) {
    linkWallet(walletUserInput: $walletUserInput) {
      id
      wallet
    }
  }
`

export const UNLINK_WALLET = gql`
  mutation unlinkWallet {
    unlinkWallet {
      id
      wallet
    }
  }
`

export const LOGIN_WALLET = gql`
  mutation loginWallet($walletUserInput: WalletUserInput!) {
    loginWallet(walletUserInput: $walletUserInput) {
      id
      wallet
      creation_method
      newly_created
    }
  }
`

export const TRANSFER_WALLET = gql`
  mutation transferWallet($walletUserInput: WalletUserInput!) {
    transferWallet(walletUserInput: $walletUserInput) {
      id
      wallet
    }
  }
`

export const UPDATE_POKEN_WITHDRAW = gql`
  mutation updatePoken($pokenInput: UpdatePokenInput!, $id: String!) {
    updatePoken(updatePokenInput: $pokenInput, id: $id) {
      id
      wallet
      network
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateProfile($id: String!, $updateUserInput: UpdateUserInput!, $profile_picture: Upload) {
    updateUser(id: $id, updateUserInput: $updateUserInput, profile_picture: $profile_picture) {
      id
      wallet
      cloud_wallet
      cloud_wallet_deployed
      name
      email
      profile_picture
      certified
      description
      nsfw
      deleted
    }
  }
`

export const UPDATE_FOLLOW_PROFILE = gql`
  mutation updateFollow($followInput: FollowInput!) {
    updateFollow(followInput: $followInput) {
      id
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser($createInput: CreateInput!) {
    createUser(createInput: $createInput) {
      id
      email
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const LOGIN_USER = gql`
  mutation login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      id
      wallet
      cloud_wallet
      cloud_wallet_deployed
      mfa {
        enabled
      }
    }
  }
`

export const FORGOT_PASS = gql`
  mutation forgotPassUser($email: String!) {
    forgotPassUser(email: $email) {
      id
      email
    }
  }
`

export const UPDATE_PASS = gql`
  mutation updateUserPass($updateUserPassInput: UpdateUserPassInput!) {
    updateUserPass(updateUserPassInput: $updateUserPassInput) {
      id
      email
      wallet
      cloud_wallet
      cloud_wallet_deployed
      mfa {
        enabled
      }
    }
  }
`

export const CREATE_AGE = gql`
  mutation createAge {
    createAge {
      id
      sessionId
    }
  }
`

export const SEND_AGE_DATA_LAYER = gql`
  mutation sendAgeDataLayer {
    sendAgeDataLayer {
      id
    }
  }
`
