import React from 'react'
import { Modal } from '@thepokencompany/ui'

import { CHAIN_SCAN_HOST } from '../../utils/constants'
import { useWalletContext } from '../../context/walletContext'

export default function ProcessingTransaction() {
  const { transactionInfos } = useWalletContext()

  if (!transactionInfos) return null

  const isAllowance = transactionInfos.type === 'Allowance'

  return (
    <>
      <Modal.Header>{isAllowance ? `Approve your Poken for trading` : `Transaction in progress`}</Modal.Header>
      <Modal.Separator />

      <div className='flex flex-col max-h-screen'>
        <div className='flex flex-col justify-center items-center'>
          <img
            src={transactionInfos?.chain === 'BSC' ? '/images/icons/Binance.svg' : '/images/icons/ethereum.svg'}
            className='h-20'
          />
        </div>
        <div className='mt-2 w-full'>
          {isAllowance ? (
            <p>
              To Approve Pokmi to trade this token, you must first complete a free (plus gas) transaction. Confirm it in
              your wallet and keep this tab open! You might notice a very large number being requested for approval -
              this is simply the maximum amount, meaning you&apos;ll never have to do this approval again. It also
              doesn&apos;t allow Pokmi to transfer that amount from you - the amount you sign in the next is all that
              can be traded on your behalf.
            </p>
          ) : (
            <p>
              A transaction for <span className='text-primary text'>{transactionInfos?.type}</span> has been triggered
              in your wallet.
            </p>
          )}
          {transactionInfos?.hash ? (
            <p className='mt-2'>
              Transaction in progress: view it on{' '}
              <a
                target='_blank'
                href={`${CHAIN_SCAN_HOST[transactionInfos?.chain || 'BSC']}/tx/${transactionInfos?.hash}`}
                rel='noreferrer'
                className='underline cursor-pointer'
              >
                {CHAIN_SCAN_HOST[transactionInfos?.chain || 'BSC'].split('//')[1]}
              </a>
            </p>
          ) : (
            <p className='mt-2'>
              Please <span className='text-primary text'>verify your wallet</span> in order to continue.
            </p>
          )}
        </div>
      </div>
    </>
  )
}
