import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Button, Modal } from '@thepokencompany/ui'

import { CREATE_AGE } from '../../graphql/profile/mutations'
import { YOTI_SDK_ID } from '../../utils/constants'
import { useAPIContext } from '../../context/apiContext'
import { useTagManagerContext } from '../../context/tagManagerContext'

import apolloClient from '../../apolloClient'

export default function Age(props) {
  const { user, onClose = () => {} } = props

  const { apiRequest } = useAPIContext()
  const { sendData } = useTagManagerContext()

  const [sessionFetched, setSessionFetched] = useState(false)
  const [session, setSession] = useState(null)
  const [sessionId, setSessionId] = useState('')

  async function createSession() {
    try {
      const { data } = await apolloClient.mutate({ mutation: CREATE_AGE })
      if (data?.createAge?.sessionId) setSessionId(data.createAge.sessionId)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    ;(async function checkSession() {
      const { data } = await apiRequest({
        method: 'get',
        path: `/age/check`
      })
      if (data) setSession(data)
      setSessionFetched(true)
    })()
  }, [])

  useEffect(() => {
    if (sessionFetched && session && session.status === 'approved') {
      return onClose()
    }
    if (sessionFetched && user && (!session || session.status !== 'approved')) {
      createSession()
    }
  }, [session, sessionFetched])

  const ageDeclined = session?.status === 'declined'

  return (
    <>
      <Modal.Header>{`Confirm your age in seconds`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        <h2 className='flex-grow text-neutral-5 mt-4'>
          We will evaluate your age through a privacy centric face analysis.
        </h2>
        <h2 className='flex-grow text-neutral-5 mt-4 mb-2'>The technology does not keep any identification data.</h2>
        <p className='mt-2 mb-2 text-primary'>It works better on a smartphone.</p>
        <div className='my-4'>
          {ageDeclined ? (
            <p className='text-center'>It seems your age estimation is under 18</p>
          ) : (
            <a
              href={sessionId ? `https://age.yoti.com?sessionId=${sessionId}&sdkId=${YOTI_SDK_ID}` : null}
              target='_blank'
              className='w-full'
              rel='noreferrer'
            >
              <Button
                className='ml-auto w-full'
                disabled={!sessionId || (!session && !sessionFetched)}
                onClick={() => {
                  sendData('sign_up', { step: '4', userId: user.id })
                }}
              >
                {session ? 'Continue age estimation' : 'Start age estimation'}
              </Button>
            </a>
          )}
        </div>

        <div className='mb-4 text-sm text-center'>
          {session ? (
            !user?.kyc ? (
              <a href={`/kyc`} rel='noreferrer' target='_blank'>
                <p className='cursor-pointer'>Do a full KYC</p>
              </a>
            ) : user?.kyc.status === 'approved' ? null : (
              <p>Wait for your ongoing KYC approval</p>
            )
          ) : null}
        </div>

        {session && user?.kyc && session?.status !== 'approved' && user?.kyc?.status !== 'approved' && (
          <p className='text-neutral-5 text-s mt-2 text-center text-xs'>Send an email to contact@pokmi.com</p>
        )}

        <p className='text-neutral-5 text-s my-2 text-center text-xs'>
          More legal info, read{' '}
          <a href='https://www.pokmi.com/terms-conditions' target='_blank' className='underline' rel='noreferrer'>
            Terms of service
          </a>{' '}
          and{' '}
          <a href='https://www.pokmi.com/terms-conditions' target='_blank' className='underline' rel='noreferrer'>
            Privacy Policy
          </a>
        </p>
      </div>
    </>
  )
}
