import { useEffect, useState, createContext, useContext, useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'

import { useAPIContext } from './apiContext'
import { useConfigContext } from './configContext'
import { useUserContext } from './userContext'

import { toast } from '../components/base/Toaster'
import { GET_WAR } from '../graphql/war/queries'
import { START_WAR } from '../graphql/war/mutations'
import moment from 'moment'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useWalletContext } from './walletContext'
import { ethers } from 'ethers'
import { CONTRACT_ADDRESS, IS_TEST_NETWORK } from '../utils/constants'
import PokumiABI from '../abis/PokumiWar.json';
const WarContext = createContext(null)
export const useWarContext = () => useContext(WarContext)

export const chainsMapping = {
  BSC: IS_TEST_NETWORK ? 97 : 56,
  ERC: IS_TEST_NETWORK ? 4 : 1
}

export function WarProvider(props) {
  const { user, getMe, userFetched } = useUserContext()
  const { callContractData, getWalletProvider, currentAccount } = useWalletContext()
  const { apiRequest, graphqlRequest } = useAPIContext()
  const [war, setWar] = useState(null)
  const [remainingFetch, setRemainingFetch] = useState(false)
  const [warLocale, setLocaleWar] = useLocalStorage('war')
  const [warriors, setWarriors] = useState(0)
  const [warriorsFetched, setWarriorsFetched] = useState(false)
  useEffect(() => {
    async function init() {
      await initInfoWar()
    }
    init()
  }, [])
  async function initInfoWar() {
    try {
      const data = await graphqlRequest({
        query: GET_WAR,
        variables: {}
      })
      setWar(data.war)
      // setLocaleWar(data.war ? { ...data.war } : null)
      try{
        const provider = new ethers.providers.JsonRpcProvider(
          IS_TEST_NETWORK ? 'https://data-seed-prebsc-1-s1.binance.org:8545/' : 'https://bsc-dataseed1.binance.org/'
        )
        const pokumiContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          PokumiABI.abi,
          provider,
        );
        const players = await pokumiContract.getAllParticipants();
        let warriorHolders={};
        let forSave;
        if(players.length > 0) {
          for(const iterator of players) {
            if(isNaN(warriorHolders[iterator])){
              warriorHolders[iterator] = 1;
            }else{
              warriorHolders[iterator]++;
            }
          }
          const best = Object.entries(warriorHolders).map(item=> {return{ holder: item[0], warriors: item[1] }})
          // best.slice(0,8);

          forSave = Object.assign(
            data.war, {
              remaining: {
                bestHolders: best,
                totalHolders: Object.entries(warriorHolders).length, 
                totalWarriors: players.length,
                allInfoRemaining: Object.entries(warriorHolders),
              }
            }
          );

        }else{
          forSave = Object.assign(
            data.war, {
              remaining: {
                bestHolders: [{holder: '', warriors: 0}],
                totalHolders: 0, 
                totalWarriors: 0,
                allInfoRemaining: 0,
              }
            }
          );
        }
        // setLocaleWar(forSave);
        setWar(forSave);
        setRemainingFetch(true);
      }catch(error){toast.error(`error => ${error}`)}
                  

      return war
    } catch (error) {
      // await disconnect()
      // toast.error(error.message)
      console.log(error)
    }
  }
  useEffect(() =>{
    if(!user) return
    
    async function callInfo() {
      const infoUser = await callContractData();
      
      setWarriors(infoUser.balance)
      setWarriorsFetched(true)
    }
    if (userFetched) callInfo()

  },[user, currentAccount])
  async function sendWarrior(warriorsSend){
    const chainId = chainsMapping.BSC;
    const provider = await getWalletProvider(chainId);
    const accounts = await provider.listAccounts();
    const account = accounts[0]?.toLowerCase?.();

    const pokumiContract = new ethers.Contract(CONTRACT_ADDRESS, PokumiABI.abi, await provider.getSigner());
    try{
      const response = await pokumiContract.engageInSeason(warriorsSend);
      toast.warning(`Sent in progress`, { autoClose: false })
      await response.wait()
      
      toast.dismiss()
    } catch(error){
      toast.error(`Fail to send warriors: ${error.message}`)
    }
    try{
      toast.success(`${warriorsSend} warrior${warriorsSend > 1 ? 's' : ''} successfully sent`)
      const infoUser = await callContractData();
      let newWarriors = war?.remaining?.bestHolders.filter(el => el.holder.toLowerCase() != account)
      let newHolder = false;
      const oldSend = war?.remaining?.allInfoRemaining?.find(el => el[0].toLowerCase() === account);
      if(oldSend){
        oldSend[1] += warriorsSend
        newWarriors.unshift({ holder: oldSend[0], warriors: oldSend[1] });        
      }else{
        newWarriors.unshift({ holder: account, warriors: warriorsSend });
        newHolder = true;
      }
      const forSetNewNbrWarrior = warriors - parseInt(warriorsSend)
      setWarriors(forSetNewNbrWarrior) 
      const forSave = Object.assign(
        war, {
          remaining: {
            bestHolders: newWarriors,
            totalHolders: newHolder ? war?.remaining?.totalHolders + 1 : war?.remaining?.totalHolders, 
            totalWarriors: war?.remaining?.totalWarriors + warriorsSend
          }
        }
      );

      setWarriors(infoUser.balance)
      
      return true;
    }catch(error){
      toast.error(`Fail to update data: ${error.message}`)
    }
  }
  const values = useMemo(
    () => ({
      war,
      warriors,
      warriorsFetched,
      remainingFetch,
      setWarriors,
      sendWarrior
    }),
    [war, warriors, warriorsFetched, remainingFetch]
  )
  return <WarContext.Provider {...props} value={values} />
}
