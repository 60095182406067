import { useEffect, useState, createContext, useContext, useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'

import { useAPIContext } from './apiContext'
import { useLocalStorage } from '../hooks/useLocalStorage'

import { GET_ME } from '../graphql/profile/queries'
import { toast } from '../components/base/Toaster'
// import { Currencies } from '../utils/types'
import { useConfigContext } from './configContext'

export type User = {
  id: string
  name?: string
  username?: string
}

const UserContext = createContext(null)
export const useUserContext = () => useContext(UserContext)

export function UserProvider(props) {
  const { setCurrency } = useConfigContext()
  const { apiRequest, graphqlRequest } = useAPIContext()
  const [userFetched, setUserFetched] = useState(false)
  const [user, setUser] = useLocalStorage('user', null)
  const [, , removeAskEmail] = useLocalStorage('askEmail')
  const [userLocale, setLocaleUser] = useLocalStorage('user')
  const [blurredContent, setBlurredContent] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (userLocale) setUser(userLocale)
      const userInfo = await getMe()
      setUser(userInfo)
    })()
  }, [])

  async function getMe() {
    try {
      const data = await graphqlRequest({
        query: GET_ME,
        variables: {}
      })
      const user = data.me

      if (user) {
        Sentry.setUser({ email: user?.email, wallet: user?.wallet, cloud_wallet: user?.cloud_wallet })
        user.isOfAge = user.age?.status === 'approved' || user.kyc?.status === 'approved'
        user.nsfw = user.nsfw || !user.isOfAge
      }

      setUser(user)
      setLocaleUser(user ? { ...user, email: '' } : null)
      setUserFetched(true)

      return user
    } catch (error) {
      await disconnect()
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (user && !user.nsfw) return setBlurredContent(false)
    setBlurredContent(true)
  }, [user])

  // useEffect(() => {
  //   if (user) setCurrency(user?.currency_preference || Currencies.USD)
  // }, [user])

  const disconnect = async () => {
    apiRequest({
      method: 'post',
      path: '/logout'
    })
      .catch((error) => {
        Sentry.captureException(error)
      })
      .then(() => {
        Sentry.configureScope((scope) => scope.setUser(null))
        setUser(null)
        setLocaleUser(null)
        setUserFetched(false)
        removeAskEmail()
      })
  }

  const values = useMemo(
    () => ({
      user,
      userFetched,
      blurredContent,
      getMe,
      refresh: getMe,
      setUser,
      disconnect
    }),
    [user, userFetched, blurredContent]
  )

  return <UserContext.Provider {...props} value={values} />
}
