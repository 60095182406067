export const IS_PROD = process.env.NEXT_PUBLIC_ENV === 'production'
export const IS_TEST_NETWORK = !IS_PROD || process.env.NEXT_PUBLIC_TEST_NETWORK === 'true'

export const CONTRACT_ADDRESS = IS_TEST_NETWORK
  ? '0xfE23De4088E6c65f71Dfdf8223433a64DB698544'
  : '0x463aEed8B4529D48e9326073cB655c24857a2115'

export const YOTI_SDK_ID = process.env.NEXT_PUBLIC_YOTI_SDK_ID || ''

export const BACK_END = process.env.NEXT_PUBLIC_BACK_END || 'http://localhost:4000'

export const ROOT_DOMAIN = process.env.NEXT_PUBLIC_ROOT_DOMAIN || 'http://localhost:4000'

export const CHAIN_SCAN_HOST = {
  ERC: IS_TEST_NETWORK ? 'https://rinkeby.etherscan.io' : 'https://etherscan.io',
  BSC: IS_TEST_NETWORK ? 'https://testnet.bscscan.com' : 'https://bscscan.com'
}
