import { gql } from '@apollo/client'

export const GET_WAR = gql`
  query war {
    war {
      registration_date
      starting_date
      season_id
      prize_screenshot
      prize_coordinates
      war_is_started
      winner
      nbr_of_day
      nbr_of_warrior
      updated_at
      created_at
    }
  }
`
