import { useState, useCallback } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Modal } from '@thepokencompany/ui'

import { toast } from '../base/Toaster'
import TextInput from '../base/TextInput'
import Button from '../base/Button'

import { isValidEmail } from '../../utils'

import { useForgotPass } from '../../graphql/profile'

export default function ForgotPass(props) {
  const { onLogin } = props

  const [ForgotPass] = useForgotPass()

  const [successLink, setSuccessLink] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const onForgotPass = useCallback(async () => {
    if (!isValidEmail(email)) {
      return setEmailError(true)
    } else {
      setEmailError(false)
    }
    try {
      await ForgotPass({
        variables: { email }
      })

      toast.success('Recovery link successfully sent')
      setSuccessLink(true)
    } catch (error) {
      Sentry.captureException(error)
      toast.error('Fail to send a recovery link. Please try again later')
      setSuccessLink(false)
    }
  }, [ForgotPass, email])

  const reset = () => {
    setEmail('')
    setEmailError(false)
    setSuccessLink(false)
  }

  return (
    <>
      <Modal.Header>{`Connect with Pokmi account`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        {successLink ? (
          <div className='flex flex-col p-2 justify-center items-center'>
            <p className='text-7xl'>📩</p>

            <p className='flex-grow text-white text-center mt-2 mb-2'>
              We sent a<span className='text-primary'> recovery link </span>
              to {email}
            </p>

            <Button outlined className='cursor-pointer font-bold mt-4 w-full' onClick={reset}>
              Back
            </Button>
          </div>
        ) : (
          <>
            <div className='flex flex-col p-2 justify-center items-center'>
              {/* <img src='/images/myPokenWallet.png' className='w-24 mb-2' alt='My Poken Wallet' /> */}
              <h2 className='flex-grow text-white text-center'>
                Recover a Pokmi account for <span className='text-primary'>uncensored access</span>
              </h2>
            </div>

            <TextInput
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type={'email'}
              placeholder={'E-mail address'}
            />

            {emailError && <p className='text-red'>Please, enter a valid e-mail address</p>}

            <Button disabled={!email} className='cursor-pointer font-bold mt-4 w-full' onClick={onForgotPass}>
              Send recovery link
            </Button>

            <div className='pt-4 w-full'>
              <p className='text-center cursor-pointer' onClick={onLogin}>
                Connect
              </p>
            </div>
          </>
        )}
      </div>
    </>
  )
}
