import { useQuery, useMutation } from '@apollo/client'
import client from '../../apolloClient'
import { GET_PROFILE, GET_ACCOUNT_INFOS, GET_PROFILES } from './queries'
import {
  LINK_WALLET,
  UNLINK_WALLET,
  LOGIN_WALLET,
  TRANSFER_WALLET,
  UPDATE_PROFILE,
  UPDATE_FOLLOW_PROFILE,
  CREATE_USER,
  DELETE_USER,
  LOGIN_USER,
  FORGOT_PASS,
  UPDATE_PASS,
  UPDATE_POKEN_WITHDRAW
} from './mutations'

export const useProfiles = (variables) => {
  const { data, loading, error, ...rest } = useQuery(GET_PROFILES, { variables })
  return { data: data?.users?.items || [], loading, error, ...rest }
}

export function useProfile(id, defaultValue = {}) {
  const { data, ...rest } = useQuery(GET_PROFILE, {
    variables: {
      id,
      conn: {}
    },
    fetchPolicy: 'no-cache'
  })

  const profile = data?.user || defaultValue

  return {
    ...rest,
    profile,
    createdNftsCount: profile?.createdNfts?.totalCount,
    ownedNftsCount: profile?.ownedNfts?.totalCount
  }
}

export async function fetchProfile(id, conn = {}) {
  const { data } = await client.query({
    query: GET_PROFILE,
    variables: {
      id,
      conn
    },
    fetchPolicy: 'no-cache'
  })

  return data?.user
}

export const useWalletInfos = (id) => {
  const { data, ...rest } = useQuery(GET_ACCOUNT_INFOS, {
    variables: { id: id || '' },
    fetchPolicy: 'no-cache'
  })

  return { ...rest, walletInfos: data?.accountInfos }
}

export function useUpdateProfile() {
  return useMutation(UPDATE_PROFILE, {
    awaitRefetchQueries: true,
    refetchQueries: ['user']
  })
}

export function useFollowProfile() {
  return useMutation(UPDATE_FOLLOW_PROFILE, {})
}

export function useCreateUser() {
  return useMutation(CREATE_USER)
}

export function useDeleteUser() {
  return useMutation(DELETE_USER, {
    awaitRefetchQueries: true,
    refetchQueries: ['user']
  })
}

export function useLoginUser() {
  return useMutation(LOGIN_USER)
}

export function useForgotPass() {
  return useMutation(FORGOT_PASS)
}

export function useUpdatePass() {
  return useMutation(UPDATE_PASS)
}

export function useLinkWallet() {
  return useMutation(LINK_WALLET)
}

export function useUnlinkWallet() {
  return useMutation(UNLINK_WALLET)
}

export function useLoginWallet() {
  return useMutation(LOGIN_WALLET)
}

export function useTransferWallet() {
  return useMutation(TRANSFER_WALLET)
}

export function useUpdatePokenWithdraw(options = {}) {
  return useMutation(UPDATE_POKEN_WITHDRAW, {
    awaitRefetchQueries: true,
    ...options
  })
}
