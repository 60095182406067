import gql from 'graphql-tag'

export const ACTIVATE_VOUCHER = gql`
  mutation activateVoucher($id: String!) {
    activateVoucher(id: $id) {
      id
      type
      created_at
      expire_at
      params_pp {
        operation
      }
    }
  }
`
