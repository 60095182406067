import { useEffect, createContext, useContext, useMemo } from 'react'
import TagManager from 'react-gtm-module'
import { useAPIContext } from './apiContext'

import { useUserContext } from './userContext'
import { useWalletInfosContext } from './walletInfosContext'

import { SEND_AGE_DATA_LAYER } from '../graphql/profile/mutations'

const GTM_ID = 'GTM-K92ZVBC'

const GTM_PARAMS = {
  gtmId: GTM_ID,
  auth: process.env.NEXT_PUBLIC_GTM_AUTH,
  preview: process.env.NEXT_PUBLIC_GTM_PREVIEW
}

declare global {
  let hj
  interface Window {
    dataLayer: any
  }
}

const TagManagerContext = createContext(null)

export const useTagManagerContext = () => useContext(TagManagerContext)

export function TagManagerProvider(props) {
  const { graphqlRequest } = useAPIContext()
  const { user } = useUserContext()
  const { infos, infosCloudWallet } = useWalletInfosContext()

  useEffect(() => {
    TagManager.initialize(GTM_PARAMS)
  }, [])

  async function sendAgeDataLayer(user) {
    sendData('sign_up', { step: '5', userId: user.id })

    await graphqlRequest({ query: SEND_AGE_DATA_LAYER })
  }

  useEffect(() => {
    try {
      if (!hj || !window || typeof window === undefined || !window?.dataLayer) return

      window?.dataLayer?.push({
        event: 'userData',
        eventProps: {
          is_connected: user ? 1 : 0,
          userId: user?.id,
          kyc: !!(user?.kyc?.status === 'approved'),
          yoti: !!(user?.age?.status === 'approved'),
          is_creator: !!user?.is_creator,
          email_verified: !!user?.email_verified,
          email: user?.email,
          age_verified: !!(user?.age?.status === 'approved'),
          has_wallet: !!user?.wallet,
          has_cloud_wallet: !!user?.cloud_wallet,
          amount_poken: infos?.pknBalance?.total + infosCloudWallet?.pknBalance?.total,
          has_poken: !!(infos?.pknBalance?.total || !!infosCloudWallet?.pknBalance?.total)
        }
      })

      if (user && user.isOfAge && user.age?.dataLayerSent === false) {
        sendAgeDataLayer(user)
      }
    } catch (error) {}
  }, [user, infos])

  const sendData = (event: string, eventProps: any) => {
    try {
      if (!hj || !window || typeof window === undefined || !window?.dataLayer) return

      window?.dataLayer?.push?.({ event, eventProps })
    } catch (error) {}
  }

  const values = useMemo(() => ({ sendData }), [sendData])

  return <TagManagerContext.Provider {...props} value={values} />
}
