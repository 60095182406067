import React, { useState } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Modal } from '@thepokencompany/ui'

import Button from '../base/Button'
import { toast } from '../base/Toaster'

import { useUserContext } from '../../context/userContext'

import { useUnlinkWallet } from '../../graphql/profile'
import { useTagManagerContext } from '../../context/tagManagerContext'

export default function UnlinkWallet(props) {
  const { onUpdate = () => {} } = props

  const { getMe } = useUserContext()
  const { sendData } = useTagManagerContext()

  const [UnlinkWallet] = useUnlinkWallet()

  const [unlinking, setUnlinking] = useState(false)

  const onUnlinkWallet = async () => {
    try {
      setUnlinking(true)

      await UnlinkWallet()

      toast.success('Wallet successfully unlinked of your Pokmi account')
      sendData('wallet_disconnected', { action: 'success' })
      onUpdate ? onUpdate() : await getMe()
      setUnlinking(false)
    } catch (error) {
      setUnlinking(false)
      Sentry.captureException(error)
      toast.error(`Fail to unlink wallet of your Pokmi account: ${error.message}`)
    }
  }

  return (
    <>
      <Modal.Header>{`Confirm wallet unlink`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        <div className='flex flex-col justify-center items-center'>
          <h2 className='flex-grow text-white text-center mt-4'>
            Are you sure you want to unlink your wallet of your Pokmi account ?
          </h2>

          <Button disabled={unlinking} className='mt-4 ml-auto w-full' outlined onClick={onUnlinkWallet}>
            Unlink
          </Button>
        </div>
      </div>
    </>
  )
}
