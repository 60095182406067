import React, { ChangeEventHandler, ReactNode } from 'react'

interface TextInputProps {
  value: string | number
  label?: string | ReactNode
  placeholder?: string
  className?: string
  inputClassName?: string
  name?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  multiline?: boolean
  type?: string
  step?: number
  min?: number
  max?: number
  disabled?: boolean
  errors?: string[]
  adornment?: React.ReactNode | string
  setIsFocus?: (value: boolean) => void
  onBlur?: () => void
}

function TextInput(props: TextInputProps, ref) {
  const {
    value,
    label,
    placeholder,
    className,
    inputClassName,
    multiline,
    type = 'text',
    errors = [],
    name = '',
    step,
    min,
    max,
    adornment,
    disabled = false,
    onBlur = () => {},
    onChange = () => {},
    setIsFocus = () => {}
  } = props

  const cName = `w-auto bg-neutral-1 ${
    errors.length ? 'border-2 border-red' : 'border border-g'
  } rounded h-10 mt-2 p-2 outline-none ${className}`

  const input = multiline ? (
    <textarea
      className={`${cName} ${disabled ? 'opacity-50' : ''}`}
      value={value}
      name={name}
      onChange={onChange}
      onFocus={() => setIsFocus(true)}
      onBlur={() => {
        setIsFocus(false)
        onBlur()
      }}
      placeholder={placeholder}
      style={{ minHeight: '4rem' }}
      disabled={disabled}
      ref={ref}
    ></textarea>
  ) : (
    <div className={`${cName} flex`}>
      <input
        className={`flex w-full flex-grow bg-transparent outline-none px-2 ${
          disabled ? 'opacity-50' : ''
        } ${inputClassName}`}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          setIsFocus(false)
          onBlur()
        }}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        ref={ref}
      />
      {adornment && <div className='flex items-center ml-2'>{adornment}</div>}
    </div>
  )

  return (
    <div className={`my-2 w-full flex flex-col ${className}`}>
      <label className='text-neutral-5 text-sm'>{label}</label>
      {errors.length
        ? errors.map((error) => (
            <p key={error} className='text-red text-sm'>
              {error}
            </p>
          ))
        : null}
      {input}
    </div>
  )
}

export default React.forwardRef(TextInput)
