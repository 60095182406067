import { gql } from '@apollo/client'

export const GET_PROFILE = gql`
  query user($id: String!, $conn: Connection!) {
    user(id: $id) {
      id
      wallet
      cloud_wallet
      cloud_wallet_deployed
      name
      username
      profile_picture
      certified
      description
      is_creator
      plan {
        id
        amount_one_month
        amount_three_month
        amount_six_month
        created_at
        updated_at
        deleted_at
        deleted
      }
      subscribersCount
      likers
      followers {
        totalCount
      }
      createdNfts(conn: $conn) {
        totalCount
      }
      ownedNfts(conn: $conn) {
        totalCount
      }
      hellos(conn: { first: 10 }) {
        items {
          id
          url
          file_compressed
          file_thumbnail
        }
      }
    }
  }
`

export const GET_TOP_CREATOR_IDS = gql`
  query topCreatorIds($filter: FilterUserInput!, $conn: Connection!) {
    topCreatorIds(filter: $filter, conn: $conn) {
      totalCount
      items {
        id
        username
      }
    }
  }
`

export const GET_PROFILES = gql`
  query user($filter: FilterUserInput!, $conn: Connection!) {
    users(filter: $filter, conn: $conn) {
      items {
        id
        wallet
        cloud_wallet
        cloud_wallet_deployed
        name
        username
        email
        profile_picture
        cover_picture
        certified
        description
        createdNfts(conn: { first: 1 }) {
          totalCount
        }
        is_creator
      }
    }
  }
`

export const GET_ME = gql`
  query me {
    me {
      id
      wallet
      cloud_wallet
      cloud_wallet_deployed
      wallet_preference
      name
      username
      email
      profile_picture
      cover_picture
      currency_preference
      certified
      is_of_age
      description
      email_verified
      has_password
      password
      deleted
      is_creator
      nsfw
      age {
        estimation
        status
        dataLayerSent
      }
      notifications {
        newsletter
        bidNft
        higherBidNft
        soldNft
        wonNft
        creator
      }
      social {
        items {
          service
          active
          externalId
          pokenArmyProgram
        }
      }
      plan {
        id
        amount_one_month
        amount_three_month
        amount_six_month
        created_at
        updated_at
        deleted_at
        deleted
      }
      referrer {
        id
        wallet
        cloud_wallet
        cloud_wallet_deployed
        username
        name
      }
      referrals(conn: {}) {
        totalCount
        items {
          erc
          bsc
        }
      }
      subscriptions(conn: {}) {
        items {
          id
          created_at
          expire_at
          creator {
            id
            name
            username
          }
        }
      }
      pokens {
        private
        first
        second
        third
        wallet
        network
        id
        airdrops {
          amount
          date
        }
      }
      followings {
        items {
          follower {
            id
          }
          following {
            id
          }
        }
      }
      kyc {
        status
        concerns {
          message
          service
          status
        }
        updated_at
      }
      mfa {
        enabled
      }
      likes(conn: {}) {
        items {
          nft {
            id
          }
        }
      }
    }
  }
`

export const CHECK_PROFILE = gql`
  query user($id: String!) {
    user(id: $id) {
      id
    }
  }
`

export const GET_ACCOUNT_INFOS = gql`
  query accountInfos($id: String!, $refresh: AccountRefreshInput) {
    accountInfos(id: $id, refresh: $refresh) {
      address
      balance_pkn_erc
      balance_pkn_bsc
      staked_pkn_erc
      staked_pkn_bsc
      staked_pkn_erc_12
      staked_pkn_bsc_12
      staked_pkn_erc_24
      staked_pkn_bsc_24
      deposit_staked_pkn_erc_12
      deposit_staked_pkn_bsc_12
      deposit_staked_pkn_erc_24
      deposit_staked_pkn_bsc_24
      unlock_time_staked_pkn_erc
      unlock_time_staked_pkn_bsc
      unlock_time_staked_pkn_erc_12
      unlock_time_staked_pkn_bsc_12
      unlock_time_staked_pkn_erc_24
      unlock_time_staked_pkn_bsc_24
      pooled_pkn_erc
      pooled_pkn_bsc
      allowance_pkn_erc
      allowance_pkn_bsc
      approval_erc
      approval_bsc
      pooling_distributions {
        pooled_pkn_earned_erc
        pooled_pkn_earned_bsc
        pool_total
      }
    }
  }
`
