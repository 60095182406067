import { useState } from 'react'
import { Button } from '@thepokencompany/ui'
import Link from 'next/link'

import AgeModal from '../Modals/Age'

import { useModalContext } from '../../context/modalContext'
import { useUserContext } from '../../context/userContext'
import { useTagManagerContext } from '../../context/tagManagerContext'

export default function Check18Plus({ onLogin, onClose }) {
  const { openModal } = useModalContext()
  const { user, disconnect } = useUserContext()
  const { sendData } = useTagManagerContext()

  const [rejectCheck, setRejectCheck] = useState(false)

  const openAgeModal = () => {
    sendData('sign_up', { step: '3', userId: user.id })
    openModal({
      preventClose: true,
      content: <AgeModal user={user} onClose={onClose} />
    })
  }

  const onConnect = async () => {
    await disconnect()
    onLogin()
  }

  const onDisconnect = async () => {
    await disconnect()
    onClose()
  }

  return (
    <>
      <div className='flex flex-col max-h-screen'>
        <div className='px-2 pb-2'>
          <Link href={'/account'}>
            <a>
              <Button outlined className='w-auto'>
                Manage Your Account
              </Button>
            </a>
          </Link>
        </div>
        {rejectCheck ? (
          <div className='flex flex-col p-2 justify-center items-center'>
            <p className='text-7xl'>🎂</p>
            <h2 className='flex-grow text-white text-center mt-4'>
              We are looking forward to seeing you when
              <span className='font-bold underline'> you are over 18</span>
            </h2>
            <div className='py-4 w-full'>
              <Button className='w-full cursor-pointer' onClick={() => setRejectCheck(false)}>
                Back
              </Button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col p-2 justify-center items-center'>
            <p className='text-7xl'>🔞</p>
            <h2 className='flex-grow text-white text-center mt-4'>
              Confirm you are over 18
              <span className='font-bold underline'> once and for all</span>
            </h2>
            <div className='py-4 w-full'>
              <Button className='w-full cursor-pointer' onClick={openAgeModal}>
                I&apos;m over 18
              </Button>
            </div>
            <p className='text-center cursor-pointer' onClick={() => setRejectCheck(true)}>
              I&apos;m not yet over 18
            </p>
            <div className='flex items-center mt-2 space-x-2 text-center text-neutral-5 text-sm'>
              <p className='cursor-pointer underline' onClick={onConnect}>
                Connect with another account
              </p>
              <p>or</p>
              <p className='cursor-pointer underline' onClick={onDisconnect}>
                Disconnect
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
