import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import { ModalProvider } from '../context/modalContext'
import { ConnectProvider } from '../context/connectContext'
import { APIProvider } from '../context/apiContext'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../apolloClient'
import { UserProvider } from '../context/userContext'
import { WalletProvider } from '../context/walletContext'
import { WarProvider } from '../context/warContext'
import { WalletInfosProvider } from '../context/walletInfosContext'
import { ConfigProvider } from '../context/configContext'
import { NavigationProvider } from '../context/navigationContext'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider } from '@emotion/react'

import createEmotionCache from '../utils/emotionCache'
import { TagManagerProvider } from '../context/tagManagerContext'

const clientSideEmotionCache = createEmotionCache()
function MyApp({ Component, pageProps }: AppProps) {
  return (
    <NavigationProvider>
    <ConfigProvider>
    <ModalProvider>
      <APIProvider>
        <ApolloProvider client={apolloClient}>
          <UserProvider>
            <WalletInfosProvider>
              <WalletProvider>
              <TagManagerProvider>
                <ConnectProvider>
                  <WarProvider>
                    <Component {...pageProps} />
                  </WarProvider>
                </ConnectProvider>
                </TagManagerProvider>
              </WalletProvider>
            </WalletInfosProvider>
          </UserProvider>
        </ApolloProvider>
      </APIProvider>
    </ModalProvider>
    </ConfigProvider>
    </NavigationProvider>
  ) 
}

export default MyApp
