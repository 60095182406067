import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import { ethers, utils } from 'ethers'
import * as Sentry from '@sentry/nextjs'
import { toast } from 'react-toastify'

import ProcessingTransaction from '../components/ModalWallet/ProcessingTransaction'
import ChooseWallet from '../components/ModalWallet/ChooseWallet'
import LinkWallet from '../components/ModalWallet/LinkWallet'
import LoginWallet from '../components/ModalWallet/LoginWallet'
import ChangeMetamaskWallet from '../components/ModalWallet/ChangeMetamaskWallet'
import ChangeMetamaskChain from '../components/ModalWallet/ChangeMetamaskChain'
import ChangeWalletConnectWallet from '../components/ModalWallet/ChangeWalletConnectWallet'
import ChangeWalletConnectChain from '../components/ModalWallet/ChangeWalletConnectChain'

import wETHABI from '../abis/weth.abi'
import PokumiABI from '../abis/PokumiWar.json';
import { usePrevious } from '../utils'
import { CONTRACT_ADDRESS, IS_TEST_NETWORK } from '../utils/constants'
import { useModalContext } from './modalContext'

import { useUserContext } from './userContext'
import { Providers } from '../utils/types'
import UnlinkWallet from '../components/ModalWallet/UnlinkWallet'
import { useWarContext } from './warContext'

declare global {
  interface Window {
    ethereum: any
  }
}

interface WalletProviderOptions {
  chainId?: number
  reset?: boolean
}

export function areSameWallet(w1?: string, w2?: string): boolean {
  if (typeof w1 !== 'string' || typeof w2 !== 'string') return false
  return w1 === w2
}

const networks = {
  1: 'mainnet',
  4: 'rinkeby',
  56: 'binance',
  97: 'binance'
}

export const chainsName = {
  1: 'Ethereum Mainnet',
  4: 'Rinkeby Test Net',
  56: 'Binance Smart Chain',
  97: 'BSC Testnet'
}

export const chains = {
  1: {
    chainId: '0x1',
    chainSymbol: 'ERC'
  },
  4: {
    chainId: '0x4',
    chainSymbol: 'ERC'
  },
  56: {
    chainId: `0x38`,
    chainSymbol: 'BSC',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  97: {
    chainId: `0x61`,
    chainName: 'Binance Smart Chain - Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      'https://data-seed-prebsc-2-s1.binance.org:8545/',
      'https://data-seed-prebsc-1-s2.binance.org:8545/',
      'https://data-seed-prebsc-2-s2.binance.org:8545/',
      'https://data-seed-prebsc-1-s3.binance.org:8545/',
      'https://data-seed-prebsc-2-s3.binance.org:8545/'
    ],
    blockExplorerUrls: ['https://testnet.bscscan.com/']
  }
}

export const chainsMapping = {
  BSC: IS_TEST_NETWORK ? 97 : 56,
  ERC: IS_TEST_NETWORK ? 4 : 1
}

export type Chain = 'ERC' | 'BSC'

const WalletContext = createContext(null)
export const useWalletContext = () => useContext(WalletContext)

export function WalletProvider(props) {
  const { user, getMe, userFetched } = useUserContext()
  const { openModal, closeModal, modalProps } = useModalContext()

  const [client, setClient] = useState(null)
  const [provider, setProvider] = useState(null)
  const [cachedProvider, setCachedProvider] = useState(null)

  const [currentAccount, setCurrentAccount] = useState('')
  const [currentChain, setCurrentChain] = useState(null)

  const [transactionInfos, setTransactionInfos] = useState(null)
  const prevTransactionInfos: any = usePrevious(transactionInfos)

  useEffect(() => {
    async function init() {
      await initWeb3Modal()
    }
    init()
  }, [])

  useEffect(() => {
    if (!transactionInfos) return closeModal()
    if (transactionInfos.hash || (prevTransactionInfos && prevTransactionInfos.type !== transactionInfos.type)) {
      return
    }

    return openModal({
      preventClose: true,
      content: <ProcessingTransaction />
    })
  }, [transactionInfos])

  async function initWeb3Modal(chainId = 1) {
    const web3Modal = new Web3Modal({
      network: networks[chainId],
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            chainId,
            network: networks[chainId],
            rpc: {
              1: 'https://old-restless-star.quiknode.pro',
              4: 'https://rinkeby-light.eth.linkpool.io',
              56: 'https://bsc-dataseed.binance.org',
              97: 'https://data-seed-prebsc-1-s1.binance.org:8545'
            },
            qrcodeModalOptions: {
              mobileLinks: ['trust'],
              desktopLinks: ['encrypted ink']
            }
          }
        }
      }
    })

    setClient(web3Modal)

    defineCachedProvider(web3Modal)

    return web3Modal
  }

  function accountsChanged(accounts: string[]) {
    const account: string = accounts[0]?.toLowerCase?.()
    setCurrentAccount(account)
  }

  function chainChanged(chainId) {
    setCurrentChain(parseInt(chainId, 16))
  }

  async function defineCachedProvider(client) {
    const currentCachedProvider = client?.providerController?.cachedProvider
    if (!currentCachedProvider) return setCachedProvider(null)

    setCachedProvider(
      currentCachedProvider === 'injected'
        ? client?.providerController.injectedProvider
        : client?.userOptions.find((option) => option.name.toLowerCase() === currentCachedProvider)
    )
  }

  async function initWeb3Provider(providerId, newClient?) {
    if (provider && cachedProvider?.id === providerId) {
      return new ethers.providers.Web3Provider(provider)
    }
    const web3Provider = newClient ? await newClient.connectTo(providerId) : await client.connectTo(providerId)

    if (providerId === 'injected') {
      web3Provider.on('accountsChanged', accountsChanged)
      web3Provider.on('chainChanged', chainChanged)
    }

    setProvider(web3Provider)

    await defineCachedProvider(client)

    const newProvider = new ethers.providers.Web3Provider(web3Provider)

    const accounts = await newProvider.listAccounts()
    const account: string = accounts[0]?.toLowerCase?.()
    setCurrentAccount(account)
    const chain = await newProvider.getNetwork()
    setCurrentChain(chain.chainId)

    return newProvider
  }

  async function resetCachedProvider() {
    await client.clearCachedProvider()
  }

  async function defineContext(newProvider, chainId) {
    if (provider && provider.name !== newProvider.name) await resetCachedProvider()

    if (newProvider.name === Providers.metamask) {
      return { client, provider: newProvider, web3Provider: await initWeb3Provider('injected') }
    }

    const isAllowedProvider = client.userOptions.find((option) => option.name === newProvider.name)
    if (!isAllowedProvider) {
      return toast.error(`Provider ${newProvider.name} is not available`)
    }

    const providerName = newProvider.name.toLowerCase()
    const availableChain = client.providerController.providerOptions[providerName].options.chainId
    const newClient = chainId && availableChain !== chainId ? await initWeb3Modal(chainId) : client

    return { provider: newProvider, client: newClient, web3Provider: await initWeb3Provider(providerName, newClient) }
  }

  async function onChooseWallet(provider, preferredChain) {
    try {
      const chainId = chainsMapping[preferredChain]
      if (provider.name === Providers.walletConnect) window?.localStorage?.removeItem('walletconnect')
      const context = await defineContext(provider, chainId)
      return context
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async function chooseWalletProvider(options: WalletProviderOptions = {}): Promise<any> {
    try {
      const { reset } = options
      if (!reset && cachedProvider) {
        return {
          client,
          provider: cachedProvider,
          web3Provider: await initWeb3Provider(cachedProvider.id || cachedProvider.name.toLowerCase())
        }
      }

      return new Promise(async (resolve) => {
        openModal({
          content: (
            <ChooseWallet
              onChoose={async (provider, preferredChain) => {
                const context = await onChooseWallet(provider, preferredChain)
                resolve(context)
              }}
              providers={client.userOptions}
            />
          )
        })
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async function linkWallet(chainId) {
    try {
      const { provider, web3Provider } = await chooseWalletProvider({ chainId, reset: true })
      if (!web3Provider) return

      const accounts = await web3Provider.listAccounts()
      const account: string = accounts[0]?.toLowerCase?.()
      if (!account) {
        const accounts = await provider.send('eth_requestAccounts', [])
        const account = accounts[0]?.toLowerCase?.()
        setCurrentAccount(account)
      }

      await new Promise((resolve) => {
        openModal({
          content: (
            <LinkWallet
              provider={provider}
              onUpdate={async () => {
                await getMe()
                resolve(null)
                closeModal()
              }}
            />
          )
        })
      })
    } catch (error) {}
  }

  async function unlinkWallet() {
    try {
      setCurrentAccount(null)

      await new Promise((resolve) => {
        openModal({
          content: (
            <UnlinkWallet
              onUpdate={async () => {
                await getMe()
                resolve(null)
                closeModal()
              }}
            />
          )
        })
      })
    } catch (error) {}
  }

  async function loginWallet(chainId, reset = true, walletProvider) {
    try {
      const { provider, web3Provider } = walletProvider || (await chooseWalletProvider({ chainId, reset }))
      if (!web3Provider) return

      const accounts = await web3Provider.listAccounts()
      const account: string = accounts[0]?.toLowerCase?.()
      if (!account) {
        const accounts = await provider.send('eth_requestAccounts', [])
        const account = accounts[0]?.toLowerCase?.()
        setCurrentAccount(account)
      }

      await new Promise((resolve) => {
        openModal({
          content: (
            <LoginWallet
              provider={provider}
              onUpdate={async () => {
                closeModal()
                await getMe()
                resolve(null)
              }}
            />
          )
        })
      })
    } catch (error) {}
  }

  async function getSignatureContent(action: string) {
    const { web3Provider } = await chooseWalletProvider()
    if (!web3Provider) return

    const accounts = await web3Provider.listAccounts()
    const account: string = accounts[0]?.toLowerCase?.()
    if (!account) return

    const salt = Math.random().toString(36)

    const signer = await web3Provider.getSigner()

    if (action === 'login') {
      const signature = await signer.signMessage(
        `Welcome to Pokmi !\nThis signature is to log in and it's free.\n\nYour account: ${account}\nSalt: ${salt}\n`
      )
      return {
        accountAddress: account,
        signature,
        salt
      }
    } else {
      const signature = await signer.signMessage(
        `This signature is to link your wallet and it's free.\n\nYour account: ${account}\nSalt: ${salt}\n`
      )
      return {
        accountAddress: account,
        signature,
        salt
      }
    }
  }

  async function askMetamaskToChangeWallet(provider) {
    return new Promise(async (resolve, reject) => {
      openModal({
        content: (
          <ChangeMetamaskWallet
            onUpdate={() => {
              resolve(null)
            }}
          />
        )
      })

      try {
        const accounts = await provider.send('eth_requestAccounts', [])
        const account = accounts[0]?.toLowerCase?.()
        setCurrentAccount(account)
      } catch (error) {
        reject(error)
      }
    })
  }

  async function askMetamaskToChangeChain(web3Provider, chainId) {
    if (!chainId) throw new Error('Missing chain id')

    return new Promise(async (resolve, reject) => {
      openModal({
        content: (
          <ChangeMetamaskChain
            desiredChain={chainId}
            onUpdate={() => {
              resolve(null)
            }}
          />
        )
      })

      const chainConfig = chains[chainId]
      if (!chainConfig) {
        throw new Error(`Unknown chain ${chainId}`)
      }
      try {
        await web3Provider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainConfig.chainId }]
        })
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await web3Provider.provider.request({
              method: 'wallet_addEthereumChain',
              params: [chainConfig]
            })
          } catch (addError) {
            reject(addError)
          }
        }
      }
    })
  }

  async function resetWalletConnectSession(chainId) {
    try {
      window?.localStorage?.removeItem('walletconnect')
      const provider = client.userOptions.find((option) => option.name === Providers.walletConnect)
      return await defineContext(provider, chainId)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async function askWalletConnectToChangeWallet(chainId) {
    return new Promise(async (resolve) => {
      openModal({
        content: (
          <ChangeWalletConnectWallet
            onUpdate={() => {
              resolve(null)
            }}
            onClick={async () => {
              await resetWalletConnectSession(chainId)
            }}
          />
        )
      })
    })
  }

  async function askWalletConnectToChangeChain(chainId) {
    return new Promise(async (resolve) => {
      openModal({
        content: (
          <ChangeWalletConnectChain
            desiredChain={chainId}
            onUpdate={() => {
              resolve(null)
            }}
            onClick={async () => {
              await resetWalletConnectSession(chainId)
            }}
          />
        )
      })
    })
  }


  async function getWalletProvider(chainId) {
    const { web3Provider, provider } = await chooseWalletProvider({ chainId })

    const chain = await web3Provider.getNetwork()
    if (!chain || chain.chainId !== chainId) {
      if (provider.name === Providers.metamask) {
        await askMetamaskToChangeChain(web3Provider, chainId)
      }
      if (provider.name === Providers.walletConnect) {
        await askWalletConnectToChangeChain(chainId)
      }
    }

    const accounts = await web3Provider.listAccounts()
    const account = accounts[0]?.toLowerCase?.()
    if (!account) {
      if (provider.name === Providers.metamask) {
        await askMetamaskToChangeWallet(web3Provider)
      }
      if (provider.name === Providers.walletConnect) {
        await askWalletConnectToChangeWallet(chainId)
      }
    }

    return initWeb3Provider(provider.id || provider.name.toLowerCase())
  }
  // useEffect(() => {
  //   callContractData()
  // }, [currentAccount])

  async function callContractData() {
    let account = currentAccount;
    const chainId = chainsMapping.BSC;

    try {
      const provider = await getWalletProvider(chainId)
      if (!provider) return
      
      if(!currentAccount) {
        const accounts = await provider.listAccounts();
        account = accounts[0];
        setCurrentAccount(account);
      }
      const pokumiContract = new ethers.Contract(CONTRACT_ADDRESS, PokumiABI.abi, await provider.getSigner())
      const balance = await pokumiContract.balanceOf(account, 1);

      return { balance: parseInt(balance.toString()) }
    
    }catch(err){console.log('error', err)}
  }
  const values = useMemo(
    () => ({
      client,
      cachedProvider,
      currentChain,
      currentAccount,
      transactionInfos,
      getWalletProvider,
      linkWallet,
      unlinkWallet,
      loginWallet,
      onChooseWallet,
      getSignatureContent,
      callContractData,
    }),
    [client, currentChain, currentAccount, modalProps, transactionInfos]
  )

  return <WalletContext.Provider {...props} value={values} />
}
