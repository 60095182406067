import { useEffect, useState, createContext, useContext, useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'

import { useAPIContext } from './apiContext'
import { useLocalStorage } from '../hooks/useLocalStorage'

import Login from '../components/Connection/Login'
// import Register from '../components/Connection/Register'
import ForgotPass from '../components/Connection/ForgotPass'
import SetPass from '../components/Connection/SetPass'
import Check18Plus from '../components/Connection/Check18Plus'
import AskForEmail from '../components/Connection/AskForEmail'

import { useUserContext } from './userContext'
import { useRouter } from 'next/router'
import { useModalContext } from './modalContext'
import { useNavigationContext } from './navigationContext'

import { ACTIVATE_VOUCHER } from '../graphql/voucher/mutations'
// import { usePokAndPlayContext } from './pokAndPlayContext'

const AUTH_PATHS = ['/auth/verify', '/auth/reset']

interface LoginOption {
  mfa: any
}

const ConnectContext = createContext(null)
export const useConnectContext = () => useContext(ConnectContext)

export function ConnectProvider(props) {
  const router = useRouter()
  const { apiRequest, graphqlRequest } = useAPIContext()
  const { openModal, closeModal } = useModalContext()
  const { user, userFetched, getMe } = useUserContext()
  const { previousPage } = useNavigationContext()
  // const { refreshTickets } = usePokAndPlayContext()

  const [askEmail, setAskEmail] = useLocalStorage('askEmail')

  const [needAge, setNeedAge] = useState(false)
  const [checkingCode, setCheckingCode] = useState(false)
  const [validCode, setValidCode] = useState(false)
  const [userData, setUserData] = useState(null)

  const { code, referralId, voucher } = router.query

  const pathname = typeof window !== 'undefined' && window.location.pathname
  const isAuthPath = AUTH_PATHS.includes(pathname)

  useEffect(() => {
    if (!needAge) return closeModal()
    if (user && !user?.isOfAge) open18Plus()
  }, [needAge, user?.isOfAge, previousPage])

  useEffect(() => {
    async function activateVoucher() {
      try {
        const data = await graphqlRequest({
          query: ACTIVATE_VOUCHER,
          variables: { id: voucher }
        })
        // if (data?.activateVoucher?.type === 'pokAndPlay') {
        //   await refreshTickets()
        // }
        router.replace(router.pathname, undefined, { shallow: true })
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    if (user?.id && voucher) activateVoucher()
  }, [voucher, user?.id])

  useEffect(() => {
    if (!code || !userFetched || !isAuthPath) return

    async function verifyCode() {
      try {
        setCheckingCode(true)
        const { data } = await apiRequest({
          path: `/verify/code?code=${code}`
        })
        setValidCode(true)
        setUserData(data)
        router.replace(router.pathname, undefined, { shallow: true })
      } catch (error) {
        setValidCode(false)
        setUserData(null)
        Sentry.captureException(error)
        router.replace(router.pathname, undefined, { shallow: true })
      }
      setCheckingCode(false)
    }

    verifyCode()
  }, [code, userFetched, isAuthPath])

  // useEffect(() => {
  //   if (userFetched && user && !user.email && !askEmail) {
  //     openAskEmail()
  //   }
  // }, [user, userFetched, askEmail])

  const openLogin = (opt: LoginOption) => {
    closeModal()

    openModal({
      content: (
        <Login
          mfaMode={opt?.mfa}
          onClose={closeModal}
          onForgot={() => openForgotPass()}
          // onCreate={() => {
          //   openRegister()
          // }}
          onSuccess={async () => {
            await getMe()
            closeModal()
          }}
        />
      )
    })
  }

  // const openRegister = (refId?) => {
  //   closeModal()

  //   openModal({
  //     content: <Register onClose={closeModal} onLogin={openLogin} referralId={referralId || refId} />
  //   })
  // }

  const openForgotPass = () => {
    closeModal()

    openModal({
      content: <ForgotPass onClose={closeModal} onLogin={openLogin} />
    })
  }

  const open18Plus = () => {
    openModal({
      content: <Check18Plus onLogin={openLogin} onClose={closeModal} />
    })
  }

  const openAskEmail = () => {
    openModal({
      content: (
        <AskForEmail
          user={user}
          onClose={async () => {
            await getMe()
            setAskEmail('wait', 3600)
          }}
        />
      ),
      onClose: () => {
        setAskEmail('wait', 3600)
      }
    })
  }

  const openSetPass = () => {
    openModal({
      content: (
        <SetPass
          code={code}
          user={userData || {}}
          onClose={() => {
            window.location.href = window.location.origin
            closeModal()
          }}
          onLogin={openLogin}
        />
      )
    })
  }

  const values = useMemo(
    () => ({
      checkingCode,
      validCode,
      setNeedAge,
      openLogin,
      // openRegister,
      openForgotPass,
      openAskEmail,
      open18Plus,
      openSetPass
    }),
    [
      checkingCode,
      validCode,
      setNeedAge,
      openLogin,
      // openRegister,
      openForgotPass,
      openAskEmail,
      open18Plus,
      openSetPass
    ]
  )

  return <ConnectContext.Provider {...props} value={values} />
}
