import React, { useState, useCallback } from 'react'
import * as Sentry from '@sentry/nextjs'

import { Modal } from '@thepokencompany/ui'

import Button from '../base/Button'
import { toast } from '../base/Toaster'

import { useWalletContext } from '../../context/walletContext'
import { useUserContext } from '../../context/userContext'

import { useLinkWallet, useTransferWallet } from '../../graphql/profile'
import { useTagManagerContext } from '../../context/tagManagerContext'

export default function LinkWallet(props) {
  const { provider, onUpdate = () => {} } = props

  const { user, getMe } = useUserContext()
  const { currentMetamaskAccount, currentWalletConnectAccount, getSignatureContent } = useWalletContext()
  const { sendData } = useTagManagerContext()

  const [LinkWallet] = useLinkWallet()
  const [TransferWallet] = useTransferWallet()

  const [linking, setLinking] = useState(false)
  const [transfering, setTransfering] = useState(false)
  const [walletExist, setWalletExist] = useState(false)

  const onLinkWallet = useCallback(async () => {
    try {
      setLinking(true)

      const signatureContent = await getSignatureContent(provider)

      await LinkWallet({
        variables: { walletUserInput: signatureContent }
      })
      toast.success('Wallet successfully linked to Pokmi account')
      sendData('wallet_connected', { action: 'success' })
      onUpdate ? onUpdate() : await getMe()
      setLinking(false)
    } catch (error) {
      setLinking(false)
      if (error.message === 'User with this wallet already exists') return setWalletExist(true)
      Sentry.captureException(error)
      toast.error(`Fail to link wallet to Pokmi account: ${error.message}`)
    }
  }, [LinkWallet, currentMetamaskAccount, currentWalletConnectAccount, getSignatureContent])

  const onTransferWallet = useCallback(async () => {
    try {
      setTransfering(true)

      const signatureContent = await getSignatureContent(provider)

      await TransferWallet({
        variables: { walletUserInput: signatureContent }
      })
      toast.success('Wallet successfully linked to Pokmi account')

      onUpdate ? onUpdate() : await getMe()
    } catch (error) {
      Sentry.captureException(error)
      toast.error(`Fail to link wallet to Pokmi account: ${error.message}`)
    }
    setTransfering(false)
  }, [TransferWallet, currentMetamaskAccount, currentWalletConnectAccount, getSignatureContent])

  return (
    <>
      <Modal.Header>{`Connect ${provider.name}`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        {walletExist ? (
          <div className='flex flex-col justify-center items-center'>
            <p className='text-7xl'>⁉</p>

            <h2 className='flex-grow text-white text-center mt-4'>
              {`You're about to replace your actual wallet, do you confirm ?`}
            </h2>
            <p className='mt-2 text-sm text-neutral-5 text-center'>
              {`If you have NFTs or staked/pooled Pokens on the actual wallet,
              you won't be able to manage or see them anymore on your Pokmi account.`}
            </p>

            <Button disabled={transfering} className='mt-4 ml-auto w-full' outlined onClick={onTransferWallet}>
              Confirm
            </Button>
            {transfering && (
              <p className='mt-2 text-sm'>
                A signing transaction has been send to you wallet. Please confirm it to continue.
              </p>
            )}

            <p className='text-neutral-5 mt-4 cursor-pointer' onClick={() => setWalletExist(false)}>
              Connect another wallet
            </p>
          </div>
        ) : (
          <div className='flex flex-col justify-center items-center'>
            <div className='w-20 w-full flex justify-center'>
              <img className='w-20' src={provider.logo} />
            </div>

            <h2 className='flex-grow text-white text-center mt-4'>
              {user?.wallet
                ? `Update ${provider.name} wallet in your Pokmi account`
                : `Add ${provider.name} wallet to your Pokmi account`}
            </h2>

            <Button disabled={linking} className='mt-4 ml-auto w-full' outlined onClick={onLinkWallet}>
              Sign Transaction in your Wallet
            </Button>

            {linking && (
              <p className='mt-2 text-sm'>
                A signing transaction has been send to you wallet. Please confirm it to continue.
              </p>
            )}
          </div>
        )}
      </div>
    </>
  )
}
