import React, { useEffect } from 'react'
import { Modal, Button } from '@thepokencompany/ui'
import { utils } from 'ethers'

import { truncateWallet } from '../../utils'
import { useUserContext } from '../../context/userContext'
import { useWalletContext } from '../../context/walletContext'
import { useModalContext } from '../../context/modalContext'

export default function ChangeWalletConnectWallet({ onClick, onUpdate = () => {} }) {
  const { user } = useUserContext()
  const { linkWallet, currentWalletConnectAccount } = useWalletContext()
  const { closeModal } = useModalContext()

  useEffect(() => {
    if (user?.wallet === currentWalletConnectAccount) {
      closeModal()
      onUpdate()
    }
  }, [user?.wallet, currentWalletConnectAccount])

  return (
    <>
      <Modal.Header>{`Change your Wallect Connect Wallet`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        <div className='flex flex-col justify-center items-center'>
          <img src='/images/icons/Wallet.svg' className='h-20 w-20' alt='NFT Wallet' />

          <h2 className='flex-grow text-white text-center my-4'>
            The wallet entered in your <span className='text-primary text'>account settings</span> is not the same as
            the one active in the current WalletConnect session
          </h2>

          {user?.wallet && (
            <>
              <p>
                Please, change the active wallet to <span>{truncateWallet(utils.getAddress(user.wallet), 10)}</span> by
                creating a new session
              </p>
              <Button className='mt-4 ml-auto w-full' outlined onClick={onClick}>
                Create new session
              </Button>
              <p className='text-neutral-5 mt-4'>or</p>
            </>
          )}

          <p className='text-neutral-5 mt-4 cursor-pointer' onClick={linkWallet}>
            Change your wallet in the account settings
          </p>
        </div>
      </div>
    </>
  )
}
