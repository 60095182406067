import { useState } from 'react'
import { Button, BUTTON_SIZES, Modal } from '@thepokencompany/ui'
import * as Sentry from '@sentry/nextjs'

import EditEmail from '../EditProfile/EditEmail'
import { toast } from '../base/Toaster'
import { useUpdateProfile } from '../../graphql/profile'

export default function AskForEmail(props) {
  const { user, onClose } = props
  const [UpdateProfile] = useUpdateProfile()

  const [showEdit, setShowEdit] = useState(false)

  const updateUser = async (input) => {
    try {
      await UpdateProfile({
        variables: {
          id: user?.id,
          updateUserInput: input
        }
      })

      toast.success('User successfully updated')

      onClose()
    } catch (error) {
      Sentry.captureException(error)
      toast.error(`Failed to update user: ${error.message}`)
    }
  }

  return (
    <>
      <Modal.Header>{`Add an email to your Pokmi Account`}</Modal.Header>
      <Modal.Separator />
      {showEdit ? (
        <EditEmail onUpdate={updateUser} />
      ) : (
        <div className='flex flex-col max-h-screen justify-center items-center'>
          <h2 className='flex-grow text-neutral-5'>
            In order to access all Pokmi features, add an email to your account.
          </h2>

          <Button size={BUTTON_SIZES.small} className='mt-4' onClick={() => setShowEdit(true)}>
            Add an email
          </Button>
        </div>
      )}
    </>
  )
}
