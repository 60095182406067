import { useEffect, useState, createContext, useContext, useMemo, useReducer } from 'react'
import { ethers } from 'ethers'
import * as Sentry from '@sentry/nextjs'

import { isBrowser } from '../utils'
import { IS_TEST_NETWORK } from '../utils/constants'
import { useUserContext } from './userContext'

import { useWalletInfos } from '../graphql/profile'
import { GET_ACCOUNT_INFOS } from '../graphql/profile/queries'

import { Chain, WalletInfos } from '../utils/types'
import { graphqlRequest } from './apiContext'

const intialInfos: WalletInfos = {
  pknBalance: {
    [Chain.ERC]: 0,
    [Chain.BSC]: 0,
    total: 0
  },
  pknAllowance: {
    [Chain.ERC]: 0,
    [Chain.BSC]: 0,
    total: 0
  },
  pknPooled: {
    [Chain.ERC]: 0,
    [Chain.BSC]: 0,
    total: 0,
    // earned: {
    //   [Chain.ERC]: 0,
    //   [Chain.BSC]: 0
    // },
    // totalInPool: 0
  },
  pknStacked: {
    [Chain.ERC]: 0,
    [Chain.BSC]: 0,
    total: 0,
    unlock: null
  },
  pknStacked12: {
    [Chain.ERC]: 0,
    [Chain.BSC]: 0,
    total: 0,
    // deposit: {
    //   [Chain.ERC]: 0,
    //   [Chain.BSC]: 0
    // },
    unlock: null
  },
  pknStacked24: {
    [Chain.ERC]: 0,
    [Chain.BSC]: 0,
    total: 0,
    // deposit: {
    //   [Chain.ERC]: 0,
    //   [Chain.BSC]: 0
    // },
    unlock: null
  },
  isApprovedForAll: {
    [Chain.ERC]: false,
    [Chain.BSC]: false
  },
  icoAllocation: {
    chain: Chain.BSC,
    amount: 0,
    amountClaimed: 0,
    monthsClaimed: 0,
    availableAmount: 0,
    startTime: Date.now()
  }
}

const WalletInfosContext = createContext(null)
export const useWalletInfosContext = () => useContext(WalletInfosContext)

export function WalletInfosProvider(props) {
  /* Context */
  const { user } = useUserContext()

  /* State */
  const [infos, setInfos] = useState<WalletInfos>({ ...intialInfos })
  const [infosCloudWallet, setCloudWalletInfos] = useState<WalletInfos>({ ...intialInfos })
  const [wallet, setWallet] = useState(null)
  const [cloudWallet, setCloudWallet] = useState(null)

  /* Hooks */
  const { walletInfos } = useWalletInfos(user?.wallet)
  const { walletInfos: cloudWalletInfos } = useWalletInfos(user?.cloud_wallet)
  const [readOnlyProviders, setReadOnlyProvider] = useReducer(
    (state, newProvider) => {
      return { ...state, ...newProvider }
    },
    { ERC: null, BSC: null }
  )

  /* Get closest unlock staking date */
  const getClosestDate = (unlockBsc: number, unlockErc: number) => {
    if (unlockBsc && unlockErc) {
      if (unlockBsc > unlockErc) return unlockErc

      return unlockBsc
    } else if (!unlockBsc && unlockErc) return unlockErc
    else if (unlockBsc && !unlockErc) return unlockBsc

    return 0
  }

  /* Update wallet infos state */
  // const updateWalletInfos = (newInfos = walletInfos) => {
  //   const newInfosState = {
  //     ...infos,
  //     pknBalance: {
  //       [Chain.ERC]: newInfos.balance_pkn_erc,
  //       [Chain.BSC]: newInfos.balance_pkn_bsc,
  //       total: newInfos.balance_pkn_erc + newInfos.balance_pkn_bsc
  //     },
  //     pknAllowance: {
  //       [Chain.ERC]: newInfos.allowance_pkn_erc,
  //       [Chain.BSC]: newInfos.allowance_pkn_bsc,
  //       total: newInfos.allowance_pkn_erc + newInfos.allowance_pkn_bsc
  //     },
  //     pknPooled: {
  //       [Chain.ERC]: newInfos.pooled_pkn_erc,
  //       [Chain.BSC]: newInfos.pooled_pkn_bsc,
  //       total: newInfos.pooled_pkn_bsc + newInfos.pooled_pkn_erc,
  //       earned: {
  //         [Chain.ERC]: newInfos.pooling_distributions.pooled_pkn_earned_erc,
  //         [Chain.BSC]: newInfos.pooling_distributions.pooled_pkn_earned_bsc
  //       },
  //       totalInPool: newInfos.pooling_distributions.pool_total
  //     },
  //     isApprovedForAll: {
  //       [Chain.ERC]: newInfos.approval_erc,
  //       [Chain.BSC]: newInfos.approval_bsc
  //     },
  //     pknStacked: {
  //       [Chain.ERC]: newInfos.staked_pkn_erc,
  //       [Chain.BSC]: newInfos.staked_pkn_bsc,
  //       total: newInfos.staked_pkn_erc + newInfos.staked_pkn_bsc,
  //       unlock: {
  //         [Chain.ERC]: newInfos.unlock_time_staked_pkn_erc,
  //         [Chain.BSC]: newInfos.unlock_time_staked_pkn_bsc,
  //         closest: getClosestDate(newInfos.unlock_time_staked_pkn_bsc, newInfos.unlock_time_staked_pkn_erc)
  //       }
  //     },
  //     pknStacked12: {
  //       [Chain.ERC]: newInfos.staked_pkn_erc_12,
  //       [Chain.BSC]: newInfos.staked_pkn_bsc_12,
  //       total: newInfos.staked_pkn_erc_12 + newInfos.staked_pkn_bsc_12,
  //       deposit: {
  //         [Chain.ERC]: newInfos.deposit_staked_pkn_erc_12,
  //         [Chain.BSC]: newInfos.deposit_staked_pkn_bsc_12
  //       },
  //       unlock: {
  //         [Chain.ERC]: newInfos.unlock_time_staked_pkn_erc_12,
  //         [Chain.BSC]: newInfos.unlock_time_staked_pkn_bsc_12,
  //         closest: getClosestDate(newInfos.unlock_time_staked_pkn_bsc_12, newInfos.unlock_time_staked_pkn_erc_12)
  //       }
  //     },
  //     pknStacked24: {
  //       [Chain.ERC]: newInfos.staked_pkn_erc_24,
  //       [Chain.BSC]: newInfos.staked_pkn_bsc_24,
  //       total: newInfos.staked_pkn_erc_24 + newInfos.staked_pkn_bsc_24,
  //       deposit: {
  //         [Chain.ERC]: newInfos.deposit_staked_pkn_erc_24,
  //         [Chain.BSC]: newInfos.deposit_staked_pkn_bsc_24
  //       },
  //       unlock: {
  //         [Chain.ERC]: newInfos.unlock_time_staked_pkn_erc_24,
  //         [Chain.BSC]: newInfos.unlock_time_staked_pkn_bsc_24,
  //         closest: getClosestDate(newInfos.unlock_time_staked_pkn_bsc_24, newInfos.unlock_time_staked_pkn_erc_24)
  //       }
  //     }
  //   }

  //   setInfos(newInfosState)
  //   return newInfosState
  // }
  /* Update wallet infos state */
  // const updateCloudWalletInfos = (newInfos = cloudWalletInfos) => {
  //   const newInfosState = {
  //     ...infosCloudWallet,
  //     pknBalance: {
  //       [Chain.ERC]: newInfos.balance_pkn_erc,
  //       [Chain.BSC]: newInfos.balance_pkn_bsc,
  //       total: newInfos.balance_pkn_erc + newInfos.balance_pkn_bsc
  //     },
  //     pknAllowance: {
  //       [Chain.ERC]: newInfos.allowance_pkn_erc,
  //       [Chain.BSC]: newInfos.allowance_pkn_bsc,
  //       total: newInfos.allowance_pkn_erc + newInfos.allowance_pkn_bsc
  //     },
  //     isApprovedForAll: {
  //       [Chain.ERC]: newInfos.approval_erc,
  //       [Chain.BSC]: newInfos.approval_bsc
  //     },
  //     pknStacked: {
  //       [Chain.ERC]: newInfos.staked_pkn_erc,
  //       [Chain.BSC]: newInfos.staked_pkn_bsc,
  //       total: newInfos.staked_pkn_erc + newInfos.staked_pkn_bsc,
  //       unlock: {
  //         [Chain.ERC]: newInfos.unlock_time_staked_pkn_erc,
  //         [Chain.BSC]: newInfos.unlock_time_staked_pkn_bsc,
  //         closest: getClosestDate(newInfos.unlock_time_staked_pkn_bsc, newInfos.unlock_time_staked_pkn_erc)
  //       }
  //     },
  //     pknStacked12: {
  //       [Chain.ERC]: newInfos.staked_pkn_erc_12,
  //       [Chain.BSC]: newInfos.staked_pkn_bsc_12,
  //       total: newInfos.staked_pkn_erc_12 + newInfos.staked_pkn_bsc_12,
  //       deposit: {
  //         [Chain.ERC]: newInfos.deposit_staked_pkn_erc_12,
  //         [Chain.BSC]: newInfos.deposit_staked_pkn_bsc_12
  //       },
  //       unlock: {
  //         [Chain.ERC]: newInfos.unlock_time_staked_pkn_erc_12,
  //         [Chain.BSC]: newInfos.unlock_time_staked_pkn_bsc_12,
  //         closest: getClosestDate(newInfos.unlock_time_staked_pkn_bsc_12, newInfos.unlock_time_staked_pkn_erc_12)
  //       }
  //     },
  //     pknStacked24: {
  //       [Chain.ERC]: newInfos.staked_pkn_erc_24,
  //       [Chain.BSC]: newInfos.staked_pkn_bsc_24,
  //       total: newInfos.staked_pkn_erc_24 + newInfos.staked_pkn_bsc_24,
  //       deposit: {
  //         [Chain.ERC]: newInfos.deposit_staked_pkn_erc_24,
  //         [Chain.BSC]: newInfos.deposit_staked_pkn_bsc_24
  //       },
  //       unlock: {
  //         [Chain.ERC]: newInfos.unlock_time_staked_pkn_erc_24,
  //         [Chain.BSC]: newInfos.unlock_time_staked_pkn_bsc_24,
  //         closest: getClosestDate(newInfos.unlock_time_staked_pkn_bsc_24, newInfos.unlock_time_staked_pkn_erc_24)
  //       }
  //     }
  //   }

  //   setCloudWalletInfos(newInfosState)
  //   return newInfosState
  // }

  useEffect(() => {
    setInfos({ ...intialInfos })
    setCloudWalletInfos({ ...intialInfos })
    setWallet(null)
    setCloudWallet(null)
    if (user?.id) {
      // refreshWalletsInfos()
    }
  }, [user?.id])

  useEffect(() => {
    getReadOnlyProviders()
  }, [])

  useEffect(() => {
    if (walletInfos) {
      setWallet(walletInfos.address)
      // updateWalletInfos()
    }
  }, [walletInfos])

  useEffect(() => {
    if (cloudWalletInfos) {
      setCloudWallet(cloudWalletInfos.address)
      // updateCloudWalletInfos()
    }
  }, [cloudWalletInfos])

  /* Refetch wallet infos */
  // const refreshWalletInfos = async (id: string, refreshOperations?: string[]) => {
  //   if (!id) return
  //   const data = await graphqlRequest({
  //     query: GET_ACCOUNT_INFOS,
  //     variables: { id, refresh: { operations: refreshOperations || [] } }
  //   })

  //   if (data?.accountInfos) {
  //     const newState = updateWalletInfos(data.accountInfos)
  //     return { ...newState }
  //   }

  //   return { ...infos }
  // }

  /* Refetch wallet infos */
  // const refreshCloudWalletInfos = async (id: string, refreshOperations?: string[]) => {
  //   if (!id) return
  //   const data = await graphqlRequest({
  //     query: GET_ACCOUNT_INFOS,
  //     variables: { id, refresh: { operations: refreshOperations || [] } }
  //   })

  //   if (data?.accountInfos) {
  //     const newState = updateCloudWalletInfos(data.accountInfos)
  //     return { ...newState }
  //   }

  //   return { ...infosCloudWallet }
  // }

  /* Refetch wallet infos */
  // const refreshWalletsInfos = async (refreshOperations?: string[]) => {
  //   const tasks = []
  //   if (user?.wallet) tasks.push(refreshWalletInfos(user.wallet, refreshOperations))
  //   if (user?.cloud_wallet) tasks.push(refreshCloudWalletInfos(user.cloud_wallet, refreshOperations))

  //   await Promise.all(tasks)
  // }

  /* Get providers */
  const getReadOnlyProviders = async (): Promise<{
    BSC?: ethers.providers.Provider
    ERC?: ethers.providers.Provider
  }> => {
    if (!isBrowser) return null
    const BSCReadOnlyProvider = new ethers.providers.JsonRpcProvider(
      IS_TEST_NETWORK ? 'https://data-seed-prebsc-1-s1.binance.org:8545/' : 'https://bsc-dataseed.binance.org/'
    )
    const ERCReadOnlyProvider = new ethers.providers.JsonRpcProvider(
      IS_TEST_NETWORK ? 'https://rinkeby-light.eth.linkpool.io/' : 'https://old-restless-star.quiknode.pro'
    )
    const providers = { BSC: BSCReadOnlyProvider, ERC: ERCReadOnlyProvider }

    setReadOnlyProvider(providers)

    return providers
  }

  /* Get closest unlock staking date */
  // const refreshIcoAllocation = async (contract) => {
  //   const { pokens } = user || {}

  //   const icoAllocation = await getICOAllocation(
  //     pokens?.wallet,
  //     pokens?.network?.toLowerCase() === 'bep20' ? Chain.BSC : Chain.ERC,
  //     contract
  //   )

  //   setInfos({
  //     ...infos,
  //     icoAllocation: { ...infos.icoAllocation, ...icoAllocation }
  //   })

  //   return icoAllocation
  // }

  /* Get ICO data */
  // const getICOAllocation = async (address: string, chain: Chain, contract: string) => {
  //   if (!readOnlyProviders[chain] || !address) return intialInfos.icoAllocation

  //   try {
  //     const vesting = new ethers.Contract(contract, vestingAbi, readOnlyProviders[chain])

  //     const alloc = await vesting.getAllocationDetails(address)
  //     const vested = await vesting.getVestedAmount(address)
  //     const startTime = await vesting.START_TIME()

  //     return {
  //       chain,
  //       amount: parseInt(ethers.utils.formatEther(alloc.amount)),
  //       amountClaimed: parseInt(ethers.utils.formatEther(alloc.amountClaimed)),
  //       monthsClaimed: alloc.monthsClaimed.toNumber(),
  //       availableAmount: parseInt(ethers.utils.formatEther(vested.amountVested)),
  //       startTime: startTime * 1000
  //     }
  //   } catch (err) {
  //     Sentry.captureException(err)

  //     return intialInfos.icoAllocation
  //   }
  // }

  /* Get stacking packs */
  // const getStakePacks = () => {
  //   const stackedPkns = infos.pknStacked.total
  //   return [...PLANS]
  //     .sort((a, b) => b.amount - a.amount)
  //     .reduce(
  //       (acc, curr) => {
  //         acc[curr.name] = Math.floor((stackedPkns - acc.totalStacked) / curr.amount)
  //         acc.totalStacked += curr.amount * acc[curr.name]
  //         return acc
  //       },
  //       { totalStacked: 0 }
  //     )
  // }

  const getBalance = (chain = Chain.BSC) => {
    const cloudWalletPreferred = user?.wallet_preference === user?.cloud_wallet
    return (cloudWalletPreferred ? infosCloudWallet.pknBalance[chain] : infos.pknBalance[chain]) || 0
  }

  const values = useMemo(
    () => ({
      wallet,
      cloudWallet,
      infos,
      infosCloudWallet,
      // stakingPacks: getStakePacks(),
      // refreshWalletInfos,
      // refreshWalletsInfos,
      // refreshIcoAllocation,
      getBalance
    }),
    [infos, infosCloudWallet]
    // [infos, infosCloudWallet, refreshWalletInfos, refreshWalletsInfos, refreshIcoAllocation, getBalance]
  )

  return <WalletInfosContext.Provider {...props} value={values} />
}
